class VolumeBar
  constructor: (@$log) ->
    @$log.debug "Creating cz-volume-bar"
  restrict: "E"
  require: "^videogular"
  templateUrl: '/assets/app/video/volume-bar.html'

  link: (scope, elem, attr, API) =>
    scope.$watch (() -> API.volume), (newVal, oldVal) =>
      @$log.debug("New API volume", newVal, oldVal)
      if newVal? and (!oldVal? or newVal > 0 or oldVal < 0.05)
        scope.volume = 100*newVal

    scope.$watch 'volume', (newVal) =>
      @$log.debug("New slider volume", newVal)
      if newVal?
        v = newVal / 100.0
        if API.volume != v
          API.setVolume(v)

angular.module('app.video').directive 'czVolumeBar', ($log) -> new VolumeBar($log)
